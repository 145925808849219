import { BaseModel } from 'modules/core/models/base-model';

export class DiplomaSetting extends BaseModel {

    client_id          = null;
    default_country_id = null;
    default_source_id  = null;
    report_logo_id     = null;

}
