import { BaseModel } from 'modules/core/models/base-model';

const IN_PREPARATION = 1;
const IN_EXECUTION   = 2;
const EXECUTED       = 3;

export class ConformityEvaluationStage extends BaseModel {

    static get IN_PREPARATION() {
        return IN_PREPARATION;
    }

    static get IN_EXECUTION() {
        return IN_EXECUTION;
    }

    static get EXECUTED() {
        return EXECUTED;
    }

}
