import { BaseModel } from 'modules/core/models/base-model';

const COMMUNITY_CODES = ['CE', 'CEE', 'UE', 'EURATOM'];

export class Diploma extends BaseModel {

    static get COMMUNITY_CODES() {
        return COMMUNITY_CODES;
    }

    country_id                    = null;
    source_id                     = null;
    type_id                       = null;
    number                        = null;
    name                          = null;
    summary                       = null;
    notes                         = null;
    viewing_document_file_id      = null;
    consolidated_document_file_id = null;
    published_at                  = null;
    status_id                     = null;
    keywords                      = null;
    commencement_date             = null;
    internal_code                 = null;
    classifications               = [];
    detailed_classifications      = [];
    classifications_tree          = [];
    legal_connections             = [];

}
