import { BaseModel } from 'modules/core/models/base-model';

const OPERATIVE = 1;
const REVOKED   = 2;

export class DiplomaStatus extends BaseModel {

    static get OPERATIVE() {
        return OPERATIVE;
    }

    static get REVOKED() {
        return REVOKED;
    }

}
