import { BaseModel } from 'modules/core/models/base-model';

export class DiplomaLegalConnection extends BaseModel {

    owner_diploma_id         = null;
    owned_diploma_id         = null;
    legal_connection_type_id = null;
    commencement_date        = null;
    notes                    = null;
    manual_owned_diploma     = null;

}
