import { BaseModel } from 'modules/core/models/base-model';

export class Source extends BaseModel {

    order       = null;
    name        = null;
    sticky      = null;
    country_id  = null;
    own_flag_id = null;
    status_id   = null;

}
