import { BaseModel } from 'modules/core/models/base-model';

export class ApplicabilityType extends BaseModel {

    acronym   = null;
    name      = null;
    icon      = null;
    status_id = null;

}
