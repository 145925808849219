import { BaseModel } from 'modules/core/models/base-model';

const ASSIGNED  = 1;
const CONCLUDED = 2;
const CANCELED  = 3;

export class PlanActionTaskStatus extends BaseModel {

    static get ASSIGNED() {
        return ASSIGNED;
    }

    static get CONCLUDED() {
        return CONCLUDED;
    }

    static get CANCELED() {
        return CANCELED;
    }

}
