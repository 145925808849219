import { BaseModel } from 'modules/core/models/base-model';

export class DiplomaClassification extends BaseModel {

    parent_id                      = null;
    country_id                     = null;
    order                          = null;
    name                           = null;
    status_id                      = null;
    diploma_classification_mapping = [];

    // report addressees
    daily_legislation_report_user_addressees    = [];
    daily_legislation_report_group_addressees   = [];
    monthly_legislation_report_user_addressees  = [];
    monthly_legislation_report_group_addressees = [];
    applicability_report_user_addressees        = [];
    applicability_report_group_addressees       = [];

}
