import { BaseModel } from 'modules/core/models/base-model';

export class DiplomaType extends BaseModel {

    country_id = null;
    acronym    = null;
    name       = null;
    status_id  = null;
    sources    = [];

}
