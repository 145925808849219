import { BaseModel } from 'modules/core/models/base-model';

export class ConformityEvaluation extends BaseModel {

    title                   = null;
    purpose                 = null;
    methodology             = null;
    entity                  = null;
    other_team_members      = null;
    other_people_to_contact = null;
    other_contacted_people  = null;
    resume                  = null;
    applicability_types     = [];
    diploma_classifications = [];
    applicable_sectors      = [];
    team_members            = [];
    users_to_contact        = [];
    contacted_users         = [];
    expected_dates          = [];
    effective_dates         = [];
    lines                   = [];
    //temporary
    checklist               = [];

}
